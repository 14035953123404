import React from "react";
import * as Icons from "./icons/";
import styles from "./Social.module.css";

export interface ISocialProps {
    type: keyof typeof Icons;
    title: string;
    url?: string;
    onClick?: () => void;
}

export class Social extends React.Component<ISocialProps> {
    render(): React.ReactNode {
        const Icon = Icons[this.props.type];
        return <a
            href={this.props.url || "#"}
            title={this.props.title}
            onClick={this.props.onClick}
            target={this.props.url && "_blank"}
            rel="noreferrer"
        >
            <Icon
                className={styles.social}
            />
        </a>;
    }
}
import React from "react";
import { ISocialProps, Social } from "..";
import styles from "./Card.module.css";

interface ICardProps {
    id: string;
    name: string;
    subtitle: string;
    website?: string;
    image?: string;
    icons: ISocialProps[];
}

export class Card extends React.Component<ICardProps> {
    render(): React.ReactNode {
        return <div id={this.props.id} className={styles.card}>
            {/* <img className={styles.image} src={this.props.image} /> */}
            <div className={styles.name}>{this.props.name}</div>
            <div className={styles.subtitle}>{this.props.subtitle}</div>
            <div className={styles.contact}>
                {this.props.website &&
                    <a
                        className={`${styles.website} clickable`}
                        href={`http://${this.props.website}/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {this.props.website}
                    </a>
                }
            </div>
            <div className={styles.icons}>
                {
                    this.props.icons.map(icon => <Social key={icon.type} {...icon} />)
                }
            </div>
        </div>
    }
}
const BASE_64_LIST = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

export class PersonalInfoEncryptor {
    private readonly shift: number;
    public constructor(shift: number) {
        this.shift = Math.abs(shift) % BASE_64_LIST.length;
    }

    public encrypt(input: string): string {
        let base64 = btoa(input);
        let output = "";
        for (let c of base64) {
            output += BASE_64_LIST[(BASE_64_LIST.indexOf(c) + this.shift) % BASE_64_LIST.length];
        }
        return output;
    }

    public decrypt(input: string): string {
        let base64 = "";
        for (let c of input) {
            base64 += BASE_64_LIST[(BASE_64_LIST.indexOf(c) - this.shift + BASE_64_LIST.length) % BASE_64_LIST.length];
        }
        let output = atob(base64)
        return output;
    }
}

(window as any).PIE = PersonalInfoEncryptor;
import React from 'react';
import { Card } from "./components/";
import styles from './App.module.css';
import { PersonalInfoEncryptor } from './PIE';

const OpenEmail = (input: string) => {
    let pie = new PersonalInfoEncryptor(7);
    let email = pie.decrypt(input);
    if (prompt("Copy and paste the email below, or click OK to open it in your default email app", email) !== null) {
        window.location.href = `mailto:${email}`;
    }
}

const OpenPhone = (input: string) => {
    let pie = new PersonalInfoEncryptor(7);
    let phone = pie.decrypt(input);
    if (prompt("Copy and paste the phone number below, or click OK to open it in your default phone app", phone) !== null) {
        window.location.href = `tel:${phone.replaceAll(" ", "").replaceAll("-", "")}`;
    }
}

function App() {
    return (
        <div>

            <div>
                <img
                    className={styles.logo}
                    src="/images/Boschwitz.png"
                    alt="Boschwitz"
                />
            </div>

            <div className={styles.container}>

                <Card
                    key="janet"
                    id="janet"
                    name="Janet Boschwitz"
                    subtitle="Realtor - Edina Realty"
                    website="janetboschwitz.com"
                    icons={[
                        {
                            type: "Facebook",
                            title: "Facebook",
                            url: "https://www.facebook.com/janetboschwitz.edinarealty",
                        },
                        {
                            type: "LinkedIn",
                            title: "LinkedIn",
                            url: "https://www.linkedin.com/in/janetboschwitz/"
                        },
                        {
                            type: "Instagram",
                            title: "Instagram",
                            url: "https://www.instagram.com/realtorjanetboschwitz/"
                        },
                        {
                            type: "Email",
                            title: "Email",
                            onClick: () => OpenEmail("ZtM1geYJi+UqhOkwkOwHgdYwitM5gdMzkOr1f9D0")
                        },
                        {
                            type: "Phone",
                            title: "Cell Phone",
                            onClick: () => OpenPhone("R6LnUqL5San3UJ7/U6P3")
                        },
                    ]}
                />
                <Card
                    key="david"
                    id="david"
                    name="David Boschwitz"
                    subtitle="Software Engineer - Amazon.com"
                    website="david.pics"
                    icons={[
                        {
                            type: "LinkedIn",
                            title: "LinkedIn",
                            url: "https://linkedin.com/in/davidboschwitz"
                        },
                        {
                            type: "Instagram",
                            title: "Instagram",
                            url: "https://instagram.com/davidboschwitz"
                        },
                        {
                            type: "GitHub",
                            title: "GitHub",
                            url: "https://github.com/davidboschwitz"
                        },
                        {
                            type: "Email",
                            title: "Email",
                            onClick: () => OpenEmail("gNM9hdYHftD6f9o+heYASt8s")
                        },
                    ]}
                />
                <Card
                    key="ben"
                    id="ben"
                    name="Ben Boschwitz"
                    subtitle="Finance Operations Analyst - Dominium"
                    icons={[
                        {
                            type: "LinkedIn",
                            title: "LinkedIn",
                            url: "https://linkedin.com/in/benjamin-boschwitz/"
                        },
                        {
                            type: "Email",
                            title: "Email",
                            onClick: () => OpenEmail("ftQ2j9Uvk9s7lrIuidMwiJ=qi97G")
                        },
                    ]}
                />
                <Card
                    key="michael"
                    id="michael"
                    name="Michael Boschwitz"
                    subtitle="Student - University of Minnesota"
                    website="michael.boschwitz.me"
                    icons={[
                        {
                            type: "LinkedIn",
                            title: "LinkedIn",
                            url: "https://linkedin.com/in/michaelboschwitz"
                        },
                        {
                            type: "GitHub",
                            title: "GitHub",
                            url: "https://github.com/bosch137"
                        },
                        {
                            type: "Email",
                            title: "Email",
                            onClick: () => OpenEmail("ftD6f9n4T6kHkd81StcrkXGG")
                        },
                    ]}
                />

                <Card
                    key="gerry"
                    id="gerry"
                    name="Gerry Boschwitz"
                    subtitle="Life in Photos"
                    website="gerry.boschwitz.com"
                    icons={[]}
                />
            </div>
        </div>
    );
}

export default App;
